import React, { useEffect, useState, useRef } from 'react';
import { Text, View, } from 'react-native';
import './App.css';
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import { TextInput, TouchableOpacity } from 'react-native-web';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Video = styled.video`
  border: 1px solid blue;
  width: 50%;
  height: 50%;
`;

export default function App() {
  const [yourID, setYourID] = useState("");
  const [yourName, setYourName] = useState("");
  const [users, setUsers] = useState({});
  const [stream, setStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [partnerText, setPartnerText] = useState({ data: [] });
  const [partnerTyping, setPartnerTyping] = useState(false);

  const userVideo = useRef();
  const partnerVideo = useRef();
  const myTextInput = useRef();
  const socket = useRef();
  const textInputPeer = useRef();

  useEffect(() => {
    socket.current = io("https://umamifl.com:22214", { transports: ['websocket'], query: { name: "User" + Math.round(Math.random() * 100) } });
    navigator?.mediaDevices?.getUserMedia({ video: true, audio: true }).then(stream => {
      setStream(stream);
      if (userVideo.current) {
        userVideo.current.srcObject = stream;
      }
    })

    socket.current.on("your-id", data => {
      console.log("yourID", data);
      setYourName(data.name);
      setYourID(data.id);
    })
    socket.current.on("allUsers", (users) => {
      setUsers(users);
    })

    socket.current.on("hey", (data) => {
      setReceivingCall(true);
      setCaller(data.from);
      setCallerSignal(data.signal);
    })
  }, []);

  function callPeer(id) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      //   config: {

      //     iceServers: [
      //         {
      //             urls: "stun:numb.viagenie.ca",
      //             username: "sultan1640@gmail.com",
      //             credential: "98376683"
      //         },
      //         {
      //             urls: "turn:numb.viagenie.ca",
      //             username: "sultan1640@gmail.com",
      //             credential: "98376683"
      //         }
      //     ]
      // },
      stream: stream,
    });
    textInputPeer.current = peer;
    peer.on("signal", data => {
      socket.current.emit("callUser", { userToCall: id, signalData: data, from: yourID })
    })

    peer.on("stream", stream => {
      if (partnerVideo.current) {
        partnerVideo.current.srcObject = stream;
      }
    });

    peer.on('data', data => {
      switch (data[0]) {
        case 2:
          setPartnerTyping(true);
          break;
        case 3:
          setPartnerTyping(false);
          break;
        case 5:
          setPartnerText(prev => {
            let newData = prev.data;
            newData.splice(parseInt(new TextDecoder().decode(data.subarray(1))), 1);
            return {
              data: newData
            }
          })
          break;
        default:
          setPartnerText(prev => {
            return {
              data: [...prev.data,
              {
                fromMe: false,
                text: data.toString()
              }]
            }
          })
      }
    })

    socket.current.on("callAccepted", signal => {
      setCallAccepted(true);
      setCaller(id);
      peer.signal(signal);
    })

  }

  function acceptCall() {
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    textInputPeer.current = peer;
    peer.on("signal", data => {
      socket.current.emit("acceptCall", { signal: data, to: caller })
    })

    peer.on("stream", stream => {
      partnerVideo.current.srcObject = stream;
    });

    // peer.on('connect', () => {
    //   peer.send("hey");
    //   // peer.send(myTextInput.current.value);
    // })

    peer.on('data', data => {
      switch (data[0]) {
        case 2:
          setPartnerTyping(true);
          break;
        case 3:
          setPartnerTyping(false);
          break;
        case 5:
          setPartnerText(prev => {
            let newData = prev.data;
            newData.splice(parseInt(new TextDecoder().decode(data.subarray(1))), 1);
            return {
              data: newData
            }
          })
          break;
        default:
          setPartnerText(prev => {
            return {
              data: [...prev.data,
              {
                fromMe: false,
                text: data.toString()
              }]
            }
          })
      }
    })

    peer.signal(callerSignal);
  }

  const sendTyping = {
    // update: function (category, requestMenu) {

    //   this.timeoutID = undefined;
    // },

    init: function (category, requestMenu) {
      if (typeof this.timeoutID === "number") {
        this.cancel();
      } else {
        textInputPeer.current.send(new Uint8Array([2]));
      }

      this.timeoutID = setTimeout(
        function () {
          textInputPeer.current.send(new Uint8Array([3]));
          this.timeoutID = undefined;
        }.bind(this),
        3000
      ); // 如果3秒钟没有调用，则调用update方法
    },

    cancel: function () {
      clearTimeout(this.timeoutID);
    },
  };

  let UserVideo;
  if (stream) {
    UserVideo = (
      <Video playsInline muted ref={userVideo} autoPlay />
    );
  }

  let PartnerVideo;
  if (callAccepted) {
    PartnerVideo = (
      <Video playsInline ref={partnerVideo} autoPlay />
    );
  }

  let incomingCall;
  if (receivingCall) {
    incomingCall = (
      <div>
        <h1>{users[caller]} is calling you</h1>
        <button onClick={acceptCall}>Accept</button>
      </div>
    )
  }
  return (
    <Container>
      <Row>
        <Text>{yourName}</Text>
        <TextInput ref={myTextInput}
          onChangeText={(text) => {
            sendTyping.init();
          }}
          onSubmitEditing={({ nativeEvent: { text } }) => {
            if (textInputPeer.current) {
              setPartnerText(prev => {
                return {
                  data: [...prev.data,
                  {
                    fromMe: true,
                    text: text
                  }]
                }
              });
              textInputPeer.current.send(text);
            }
          }} />
      </Row>
      <Row>
        {UserVideo}
        {PartnerVideo}
      </Row>
      <Row>
        {Object.entries(users).map(([key, value], index) => {
          if (key === yourID) {
            return null;
          }
          return (
            <button key={index} onClick={() => callPeer(key)}>Call {value}</button>
          );
        })}
      </Row>
      <Row>
        {incomingCall}
        {partnerTyping && <h1>{users[caller]} is typing...</h1>}
      </Row>
      <Row>
        <View style={{ flex: 1, width: "100%", }}>
          {partnerText.data.map((row, index) => {
            return (
              <TouchableOpacity key={index}
                style={[{ width: "100%" }, row.fromMe ? { textAlign: "right" } : { textAlign: "left" }]}
                onPress={() => {
                  if (textInputPeer.current) {
                    let indexBuffer = new TextEncoder().encode(index.toString());
                    let outputBuffer = new Uint8Array(indexBuffer.length + 1);
                    outputBuffer[0] = 5;
                    outputBuffer.set(indexBuffer, 1);
                    textInputPeer.current.send(outputBuffer);
                    setPartnerText(prev => {
                      let newData = [...prev.data];
                      newData.splice(index, 1);
                      return {
                        data: newData
                      }
                    })
                  }
                }}
              >
                <Text>
                  {row.text}
                </Text>
              </TouchableOpacity>
            )
          }
          )}
        </View>
      </Row>
    </Container>
  );
}